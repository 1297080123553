import React from "react";
import {CCard, CCardBody, CCol, CButton} from "@coreui/react";
import {DataGrid, esES, GridActionsCellItem, GridRowModes} from "@mui/x-data-grid";
import DataGridCrudBase from "../components/datagrid/DataGridCrudBase";
import Toasts from "../components/notificacion/Toasts";
import {StateContext} from "../components/context/StateProvider";
import NotesService from "../services/notes.service";
import NoteModal from "../components/modals/Note";
import NoteModalCreate from "../components/modals/NoteCreate";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import OrderService from "../services/order.service";

const ESTADOS_INCIDENCIAS = ['NA','Abierto', 'En Revisión', 'Cerrado'];
class Notes extends DataGridCrudBase {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {
            notesModels: {},
            notes: [],
            notesViewFilter: true,
            currentNote: undefined,
            modelGrid: 'notesModels',
            stateGrid: 'notes',
            showNote:false,
            showNoteCreate:false,
            text: '',
            title: '',
            modalNoteMode : 0,
            modalNoteAdmin: 1,
            loading: true
        };
        this.apiRef = React.createRef();
        this.apiRef.current = {};
    }

    async componentDidMount() {

        const {state, updateState} = this.context;
    
        // Obtener incidencias
        await NotesService.getNotes().then(
            async (data) => {
            
                let estados = ESTADOS_INCIDENCIAS;
                let openNotes = '';
                let invoice = null;


                const notesMap = data.map(async (note) => {
                    let itemNote = note;
                    const fechaCreado = new Date(note.creado);
                    const fechaModificado = new Date(note.modificado);
                    itemNote.id = note.id_note;
                    itemNote.titulo = note.note;
                    itemNote.estado_texto = estados[note.estado];
                    itemNote.fecha_creado = fechaCreado.toLocaleString('es-PY', { timeZone: 'UTC' });
                    itemNote.fecha_modificado= fechaModificado.toLocaleString('es-PY', { timeZone: 'UTC' });
                    itemNote.cliente = '';
                    if(note.albaran.length > 0){
                        invoice = await NotesService.getInfoInvoice(note.serie.trim(), note.albaran.trim(), note.ejercicio);         
                        
                        if(invoice && invoice.ClientesModel){
                            itemNote.titulo = invoice.ClientesModel.NOMBRE;
                            itemNote.cliente = invoice.ClientesModel.CODIGO + ' \ ' + invoice.ClientesModel.NOMBRE;
                        } 

                        itemNote.factura = invoice.FACTURA;
                    }

                    if(note.tipo_note == 'art'){
                        itemNote.referencia = note.codigo_articulo;
                    }else{
                        itemNote.referencia = note.serie.trim() + '-' + note.albaran.trim();
                    }
                    
                    if(note.estado == 1 || note.estado == 2){
                        openNotes++
                    }

                    return itemNote;
                });

                const notesMapFull = await Promise.all(notesMap);
                this.setState({notes: notesMapFull, loading: false});

                updateState({openNotes: openNotes});
            }
        ).catch((error) => {
            const toasts = Toasts.generateToast(error.message, 'error');
            updateState({toasts: [...state.toasts, toasts]})
        });

        this.apiRef.current.setFilterModel({
            items: [
             {
              id: 1,
              field: "estado_texto",
              operator: "isAnyOf",
              value: ['Abierto','En Revisión'],
             },
            ]
        })
    }

    generateColumns() {

        const columns = [
            {
                field: 'id_note',
                headerName: 'Id',
                flex: 0.3,
                editable: false
            },
            {
                field: 'titulo',
                headerName: 'Incidencia',
                flex: 2.2,
                type: 'text',
            },
            {
                field: 'referencia',
                headerName: 'Referencia',
                flex: 0.7,
                type: 'text',
            },
            {
                field: 'tipo_note',
                headerName: 'Tipo',
                flex: 0.4,
                type: 'text',
            },
            {
                field: 'nombre_empleado',
                headerName: 'Empleado',
                flex: 0.5,
                type: 'text',
            },
            {
                field: 'estado_texto',
                headerName: 'Estado',
                flex: 0.6,
                type: 'text',
            },
            {
                field: 'fecha_modificado',
                headerName: 'Modificado',
                flex: 1.1,
                type: 'text',
            },
            {
                field: 'fecha_creado',
                headerName: 'Creado',
                flex: 1.1,
                type: 'text',
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                flex: 0.3,
                cellClassName: 'actions',
                getActions: ({id}) => {

                        return [
                            <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit Note"
                            className="textPrimary"
                            onClick={() => this.handleNote(id)}
                            color="inherit"
                        />,
                        ];
                },
            }
        ];

        return columns;
    }

    async handleNote(id) {
        let note = this.state.notes.find((row) => row.id === id);
        let modalNote = note


        
        this.setState({
            showNote: true, 
            currentNote: modalNote,
            modalNoteMode: 0, 
            text: 'Datos de la incidencia: <strong>'+ modalNote.serie.trim()+'-'+modalNote.albaran.trim()+'</strong><br>' + modalNote.cliente + ' Factura: '+ modalNote.factura, 
            title: 'Atención'});
    }

    handleNoteNew() {
        console.log('handleNew')
        this.setState({showNoteCreate: true});
    }

    async handleConfirmNote(modalNote, type) {

        const {state, updateState} = this.context;
        let message = '';
        let modal = '';
        let Note = this.state.currentNote;
        console.log('handleConfirmNote', Note)
  
        //Si Note existe, actualizamos y creamos como comentario añadido
        //Si no existe creamos nueva note + comentario
        if(Note && Note.id_note){
            message = 'Se modificado la incidencia correctamente';
            modal = 'showNote';
            NotesService.registerNotesComentarios(modalNote).then(
                (resp) => {
                    
                    Note.comentarios.push(resp.data)
                   
                    NotesService.updateNote(modalNote).then(
                        (resp2) => {
                         
                            Note.estado = modalNote.estado;
                            Note.estado_texto = ESTADOS_INCIDENCIAS[modalNote.estado];
                       
                            let toasts = Toasts.generateToast(resp.message, 'success');
                            updateState({toasts: [...state.toasts, toasts]});
            
                            this.setState({
                                notes: this.state.notes.map((row) => (row.id === Note.id_note ? Note : row)),
                                [modal]: false
                            });
                        }
                    ).catch((error) => {
                        const toasts = Toasts.generateToast(error.message, 'error');
                        updateState({toasts: [...state.toasts, toasts]})
                    }); 
                }
            ).catch((error) => {
                const toasts = Toasts.generateToast(error.message, 'error');
                updateState({toasts: [...state.toasts, toasts]})
            });
        }else{

            message = 'Se ha creado la incidencia correctamente';
            modal = 'showNote';
            NotesService.registerNotes(modalNote).then(
                (resp) => {
                    
                    modalNote.id_note = resp.data.id_note
                    NotesService.registerNotesComentarios(modalNote).then(
                        (resp) => {
        
                            let toasts = Toasts.generateToast(resp.message, 'success');
                            updateState({toasts: [...state.toasts, toasts]});
            
                            this.setState({
                                notes: this.state.notes.map((row) => (row.id === Note.id_note ? Note : row)),
                                [modal]: false
                            });
                        }
                    ).catch((error) => {
                        const toasts = Toasts.generateToast(error.message, 'error');
                        updateState({toasts: [...state.toasts, toasts]})
                    });                  
                }
            ).catch((error) => {
                const toasts = Toasts.generateToast(error.message, 'error');
                updateState({toasts: [...state.toasts, toasts]})
            });
        }
        let openNotes = '';
        this.state.notes.forEach((note) => {

            if(note.estado == 1 || note.estado == 2)
                openNotes++

        });

        updateState({openNotes: openNotes});

    }

    /**
     * Gestiona cerra el modal
     * @param show
     */
     handleClose = async (show, reset) => {
        this.setState({[show]: false});
    }

    /**
     * Ver todas la inciencias
     * @param show
     */
     handleNoteViewFilter = async () => {

        if(this.state.notesViewFilter == false){
            this.apiRef.current.setFilterModel({
                items: [
                 {
                  id: 1,
                  field: "estado_texto",
                  operator: "isAnyOf",
                  value: ['Abierto','En Revisión'],
                 },
                ]
            });
            this.setState({notesViewFilter: true});

        }else{
            this.apiRef.current.setFilterModel({
                items: []
            });

            this.setState({notesViewFilter: false});
        }

        
    }

    render() {

        const {
            notesModels,
            notes,
            currentNote,
            showNote,
            showNoteCreate
        } = this.state;        
        return (
            <CCol id="notes" md={12}>
                <CCard className="p-0">
                    <CCardBody>
                    <CButton type="button" color="primary" className="px-4" onClick={() => this.handleNoteNew()}>
                     Añadir incidencia
                    </CButton>
                    <CButton type="button" color="link" variant='outline' className="px-4" onClick={() => this.handleNoteViewFilter()}>
                        Ver todas
                    </CButton>
                    </CCardBody>
                </CCard>
                <CCard className="p-3">
                    <CCardBody>
                        <DataGrid
                            rows={notes}
                            columns={this.generateColumns()}
                            rowModesModel={notesModels}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            apiRef={this.apiRef}
                            autoHeight={true}
                            initialState={{
                                filter: {
                                  filterModel: {
                                    items: [{ id:1, field: 'estado_texto', operator: 'equals', value: 'Abierto' }],
                                  },
                                },
                              }}                         
                            loading={this.state.loading}
                        />
                    </CCardBody>
                </CCard>
                <NoteModal show={showNote}
                    title={this.state.title}
                    text={this.state.text}
                    iconName='cilWarning'
                    noteModal={currentNote}
                    modalNoteMode={this.state.modalNoteMode}
                    modalNoteAdmin={this.state.modalNoteAdmin}
                    handleClose={() => this.handleClose('showNote')}
                    handleConfirm={(note) => this.handleConfirmNote(note, 'note')}></NoteModal>

                <NoteModalCreate show={showNoteCreate}
                    
                    noteModal={currentNote}
                    handleClose={() => this.handleClose('showNoteCreate')}
                    handleConfirm={(note) => this.handleConfirmNote(note, 'note')}></NoteModalCreate>

            </CCol>
        );
    }
}

export default Notes;