import React from "react";
import DeliveryService from "../services/delivery.service";
import ListDeliveries from "../components/lists/ListDeliveries";
import {CButton, CCard, CCardBody, CCol, CForm, CFormInput, CFormSelect, CSpinner} from "@coreui/react";
import {StateContext} from "../components/context/StateProvider";
import Utils from "../utils/Utils";
import Toasts from "../components/notificacion/Toasts";
import StickerService from "../services/sticker.service";
import withRouter from "../components/withRouter";
import DeliveryNavigate from "../components/sticker/DeliveryNavigate";


class PointDelivery extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {delivery: '', deliveries: []};
    }

    handleChange(event) {
        this.setState({delivery: event.target.value});
    }

    async componentDidMount() {
        const {updateState, state} = this.context;
        updateState({toasts: []});

        if (this.props.params.delivery) {

            updateState({loading: true});

            try {
                const delivery = await DeliveryService.getDelivery(this.props.params.delivery);
                this.setState({delivery: delivery});
                await this.getDelvieryMandatelo(updateState, state);
            } catch (error) {
                const toasts = Toasts.generateToast(error.response.data.message, 'error');
                updateState({toasts: [...state.toasts, toasts]})
            }
        } else {
            this.setState({delivery: '', deliveries: []});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.params.delivery !== this.props.params.delivery) {
            this.componentDidMount();
        }
    }

    componentWillUnmount() {
        const {updateState} = this.context;
        updateState({year: Utils.getCurrentYear()});
    }

    handleChangeYear(event) {
        const {updateState} = this.context;
        updateState({year: parseInt(event.target.value)});
    }

    async handleSubmit(event) {

        if (event) {
            event.preventDefault();
        }

        this.setState({deliveries: []})
        if (this.state.delivery !== "") {
            const {updateState, state} = this.context;
            updateState({loading: true});
            try {
                await this.getDeliverySage(updateState, state);
            } catch (error) {
                const toasts = Toasts.generateToast(error.response.data.message, 'error');
                updateState({toasts: [...state.toasts, toasts]})
            }

        }
    }

    /**
     * Obtener rutas de mandatelo
     * @param updateState
     * @param state
     * @returns {Promise<void>}
     */
    async getDelvieryMandatelo(updateState, state) {
        await StickerService.getStickers(this.props.params.delivery).then(
            async (data) => {
                const deliveriesMap = data.map(async (delivery) => {
                    // Obtener bultos
                    const packages = await DeliveryService.getBultos(delivery.id_ruta, delivery.serie + '-' + delivery.albaran, this.state.delivery.year);

                    // Comprobar cuantos bultos quedan por añadir
                    const packagesRemaining = delivery.bultos - packages.length;

                    // Añadir bultos escaneados
                    let packagesScanned = [];
                    let registerDate = null;

                    packages.map((pack) => {
                        packagesScanned.push({package: pack.bulto, register: (pack.registrado !== null ? new Date(pack.registrado) : null)});
                        if (pack.registrado !== null && (registerDate === null || new Date(pack.registrado) > registerDate)) {
                            registerDate = new Date(pack.registrado);
                        }
                    })

                    // Cambiar el estado en funcion de los bultos
                    let packagesState = 0;
                    if (packagesRemaining === 0) {
                        packagesState = 1;
                    }

                    return {
                        delivery: delivery.id_ruta,
                        serie: delivery.serie,
                        document: delivery.albaran,
                        customer: delivery.cliente,
                        packages: delivery.bultos,
                        agency: delivery.agencia,
                        scanned: packagesScanned,
                        remaining: packagesRemaining,
                        state: packagesState,
                        registerDate: registerDate
                    };
                });

                const deliveriesMapFull = await Promise.all(deliveriesMap);
                this.setState({deliveries: deliveriesMapFull});
                updateState({loading: false});
            }
        )
    }

    /**
     * Obtener rutas de sage
     * @param updateState
     * @param state
     * @returns {Promise<void>}
     */
    async getDeliverySage(updateState, state) {

        await DeliveryService.getShipping(this.state.delivery, state.year).then(
            async (data) => {
                const deliveriesMap = data.map(async (delivery) => {

                    // Obtener bultos
                    const packages = await DeliveryService.getBultos(delivery.CONTADOR, delivery.LETRA + '-' + delivery.NUMERO, this.state.delivery.year);

                    // Comprobar cuantos bultos quedan por añadir
                    const packagesRemaining = delivery.BULTOS - packages.length;

                    // Añadir bultos escaneados
                    let packagesScanned = [];
                    let registerDate = null;

                    packages.map((pack) => {
                        packagesScanned.push({package: pack.bulto, register: (pack.registrado !== null ? new Date(pack.registrado) : null)});
                        if (pack.registrado !== null && (registerDate === null || new Date(pack.registrado) > registerDate)) {
                            registerDate = new Date(pack.registrado);
                        }
                    })

                    // Cambiar el estado en funcion de los bultos
                    let packagesState = 0;
                    if (packagesRemaining === 0) {
                        packagesState = 1;
                    }

                    return {
                        delivery: delivery.CONTADOR,
                        serie: delivery.LETRA,
                        document: delivery.NUMERO,
                        customer: delivery.CLIENTE,
                        packages: delivery.BULTOS,
                        agency: (!Utils.isEmpty(delivery.AgenciaModel)) ? delivery.AgenciaModel.NOMBRE : [],
                        scanned: packagesScanned,
                        remaining: packagesRemaining,
                        state: packagesState,
                        registerDate: registerDate
                    };
                });

                const deliveriesMapFull = await Promise.all(deliveriesMap);
                this.setState({deliveries: deliveriesMapFull})
                updateState({loading: false});
            }
        )
    }

    render() {

        const {state} = this.context;
        const {params} = this.props;
        const {deliveries} = this.state;

        return (
            <CCol id="delivery" md={12}>
                <CCard className="p-3">
                    <CCardBody>
                        {!params.delivery ?
                            <CForm onSubmit={(e) => this.handleSubmit(e)} className="mb-3">
                                <div className="mb-3">
                                    <label htmlFor="list-year" className="form-label">Año</label>
                                    <CFormSelect className='form-control mb-2'
                                                 onChange={(e) => this.handleChangeYear(e)}
                                                 id="list-year"
                                                 name="list-year"
                                                 aria-label="Seleccionar año"
                                                 options={Utils.getArrayYears()}/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="delivery-number" className="form-label">Nº reparto</label>
                                    <CFormInput className="form-control" type="text" id="delivery-number"
                                                name="delivery-number" value={this.state.delivery}
                                                onChange={(e) => this.handleChange(e)}/>
                                </div>
                                <CButton type="submit" color="primary" className="px-4 col-12">
                                    {state.loading ?
                                        <CSpinner component="span" size="sm"
                                                  aria-hidden="true"/> : 'Buscar'}
                                </CButton>
                            </CForm>
                            : <>
                                <DeliveryNavigate delivery={this.state.delivery}/>
                            </>
                        }
                        {state.loading && params.delivery ?
                            <CSpinner component="span" size="sm" aria-hidden="true"/> : <>
                                {deliveries.length === 0 ?
                                    <>{!params.delivery ? '' :
                                        <p className='mb-0'>No existen bultos para puntear en esta ruta</p>}</> :
                                    <ListDeliveries deliveries={this.state.deliveries}/>
                                }
                            </>
                        }
                    </CCardBody>
                </CCard>
            </CCol>
        )
    }
}

export default withRouter(PointDelivery);