import React from "react";
import {StateContext} from "../context/StateProvider";
import logo from "../../assets/brand/logo.png";
import logoWhite from "../../assets/brand/logo_white.png";
import QRCode from "react-qr-code";
import Utils from "../../utils/Utils";

class PrintSticker extends React.Component {

    static contextType = StateContext;

    render() {

        const {print} = this.props;

        const packages = Array.from({length: print.packages}, (_, index) => index);

        return (<div className='hidden'>
                {packages.map(function (index) {
                    return (
                        <PackagePrint key={index} total={print.packages} index={index} print={print}/>
                    )
                })}
            </div>
        );
    }
}

class PackagePrint extends React.Component {

    render() {
        const {print, index, total} = this.props;

        const pack = index + 1;

        return (
            <div className='sticker mb-2 me-3 ms-3 mb-3'>
                <div className='sticker-header row'>
                    {!print?.dropshipping ?
                    <div className="d-flex col-12 justify-content-center">
                        <img className="sidebar-brand-full" src={logo} alt="Mandatelo" width="180"/>
                    </div> : 
                    <div className="d-flex col-12 justify-content-center">
                        <img className="sidebar-brand-full" src={logoWhite} alt="Dropshiping" width="180"/>
                    </div>                    
                    }
                </div>
                <div className='row sticker-address'>
                    {!print?.dropshipping ?
                    <div className="col-12">
                        <div className="mb-0 me-2 text-black"><strong>Origen:</strong></div>
                        <div className="mt-0 mb-0">C/ Subida al Mayorazgo 4B, 38110 Hoya Fría, S/C de Tenerife</div>
                        <div className="mt-0 mb-0">Tfn: 922212728</div>
                    </div>
                        : 
                    <div className="col-12">
                        <div className="mb-0 me-2 text-black"><strong>&nbsp;</strong></div>
                        <div className="mt-0 mb-0">&nbsp;</div>
                        <div className="mt-0 mb-0">&nbsp;</div>
                    </div>                        
                    }
                </div>
                <div className='row sticker-destination'>
                    <div className="mt-1 mb-1 col-12">
                        <div className="mb-1 me-2 text-black"><strong>Destino:</strong></div>
                        <div className="mt-1 mb-0 customer">{Utils.isFullEmpty(print?.tradename) ? print?.tradename.toUpperCase() : print?.name.toUpperCase()}</div>
                        <div className="mt-1 mb-0">{print?.address}</div>
                        <div className="mt-1 mb-0">{print?.cp} <span className='ms-4'>{print?.county}</span></div>
                        <div className="mt-1 mb-0">{print?.state} <span className='ms-4'>{print?.phone}</span></div>
                    </div>
                </div>
                <div className='row sticker-footer'>
                    <div className="col-8">
                        <div className='row'>
                            <div className="mb-1 col-12 d-inline-flex">
                                <div className="mb-0 me-2 text-black"><strong>Agencia:</strong></div>
                                <div className="mt-0 mb-0">{print?.nameAgency}</div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-1 col-12 d-inline-flex">
                                <div className="mb-0 me-2 text-black"><strong>Volumen expedición:</strong></div>
                                <div className="mt-0 mb-0">{print?.weight === 0 ? '' : print?.weight}</div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-1 col-12 d-inline-flex">
                                <div className="mb-0 me-2 text-black"><strong>Documento:</strong></div>
                                <div className="mt-0 mb-0">{print?.serie}-{print?.order}</div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-1 col-12 d-inline-flex">
                                <div className="mb-0 me-2 text-black"><strong>Ruta:</strong></div>
                                <div className="mt-0 mb-0 delivery">{print?.nameDelivery}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 qr">
                        <div className='text-center'>
                            <QRCode value={print?.serie + '-' + print?.order + '-' + pack} size={70}/>
                            <div>{print?.serie + '-' + print?.order + '-' + pack}</div>
                        </div>
                        <div className='packages m-1 text-center'>
                            <div>BULTOS</div>
                            <div>{pack}/{total}</div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className="col-12 d-inline-flex align-items-center note">
                            <strong>Nota: si observa daños en los productos debido a un embalaje dañado, debe
                                comunicarlo en el
                                mismo día de la recepción del pedido. No se aceptarán reclamaciones pasado este
                                plazo</strong>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default PrintSticker;