import React, {useContext, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {CFormTextarea, CModalTitle, CFormSelect} from "@coreui/react";
import EmployeesService from "../../services/employees.service";
import CIcon from "@coreui/icons-react";
import {cilWarning} from "@coreui/icons";
import parse from "html-react-parser";

const Note = ({show, title, text, iconName, noteModal, modalNoteMode, modalNoteAdmin, handleClose, handleConfirm}) => {

    const [error, setError] = useState('');
    const [note, setNote] = useState('');
    const [comentarios, setComentarios] = useState([]);
    const [preparers, setPreparers] = useState([]);
    const [prepare, setPrepare] = useState('');
    const [prepareName, setPrepareName] = useState('');
    const [estados, setEstados] = useState([{label:'Abierto',value: '1'},{label:'En Revisión',value: '2'},{label:'Cerrado',value: '3'}]);
    const [estado, setEstado] = useState('');
    const [disabled, setDisabled] = useState(true);
    

    useEffect(() => {
        console.log('useEffect', noteModal)
        setNote('');
        setComentarios([]);
        setEstado(noteModal?.estado); 
        
        const fetchPreparers = async () => {
            const preparersData = await initSelects('Elige preparador');
            setPreparers(preparersData);
            setPrepare(noteModal?.id_empleado);
            setPrepareName(noteModal?.nombre_empleado);
        };
        fetchPreparers();

        const fetchNotes = async () => {
            if(noteModal && noteModal.comentarios)
                setComentarios(noteModal.comentarios);
        };
        fetchNotes(); 

    }, [noteModal]);

    const initSelects = async (texto) => {
        let employees = await EmployeesService.getEmployees();

        let employeesMap = employees.map((employee) => {
            return {label: employee.nombre, value: employee.id}
        });

        employeesMap.unshift({label: texto, value: '0'});
        return employeesMap;
    }

    const handleTextChange = (e) => {
        const value = e.target.value;
        if (value.length > 250) {
            setError('El comentario no puede exceder los 250 caracteres.');
        } else {
            setNote(value);
            setError('');
            setDisabled(false);
        }
    };

    const handleConfirmation = () => {
        if (note.length > 250) {
            setError('El comentario excede los 250 caracteres.');
        } else {
            noteModal.note = note;
            noteModal.id_empleado = prepare;
            noteModal.nombre_empleado = prepareName;
            noteModal.estado = estado;

            handleConfirm(noteModal);
        }
    };

    const handleChange = (event, type) => {
        let index = event.target.selectedIndex;
        if (type === 'orderState') {
            setOrderStateType(event.target.value);
        } else  if (type === 'prepare') {
            setPrepare(event.target.value);
            setPrepareName(event.target.options[index].text);
        } else  if (type === 'estado') {
            setEstado(event.target.value);
        } else {
           
        }
    };

    let comentariosList = [];
    if(comentarios){
        comentarios.forEach((note, index) => {
            const nuevaFecha = new Date(note.creado);
            comentariosList.push(<li key={index} style={{ borderBottom: '1px solid #ccc', marginBottom:'5px' }}><strong>{note.nombre_empleado}</strong> - <small>{nuevaFecha.toLocaleString('es-PY', { timeZone: 'UTC' })}</small><br></br>{note.comentario}</li>);
        });  
    }
  

    return (
        
        <Modal size="lg" show={show} onHide={() => handleClose()} centered >
                <Modal.Header closeButton>
                    <CModalTitle>
                        {iconName !== undefined ? <CIcon icon={cilWarning} size='sm' /> : ''}
                        &nbsp;{parse(title)}</CModalTitle>
                </Modal.Header>
            <Modal.Body>
            <div className="mb-2">{parse(text)}</div>
            <div className="mb-2">
                            <label htmlFor="list-order-type" className="form-label">Tramitado por</label>
                            <CFormSelect className='form-control'
                                         onChange={(e) => handleChange(e, 'prepare')}
                                         id="preparer"
                                         value={prepare}
                                         name="preparer"
                                         aria-label="Seleccionar preparador"
                                         options={preparers}
                            />
            </div> 
            
            <div className="mb-2" style={{display: modalNoteAdmin ? 'block' : 'none' }}>
                            <label htmlFor="list-order-type" className="form-label">Estado</label>
                            <CFormSelect className='form-control'
                                onChange={(e) => handleChange(e, 'estado')}
                                id="estado"
                                value={estado}
                                name="estado"
                                aria-label="Seleccionar estado"
                                options={estados}
                            />
            </div> 
            <div className="mb-2" style={{display: modalNoteAdmin ? 'none' : 'block' }}>
                            <label htmlFor="list-order-type" className="form-label">Estado</label>
                            <CFormSelect className='form-control'
                                disabled
                                value={estado}
                                aria-label="Seleccionar estado"
                                options={estados}
                            />
            </div> 
            <div className="mb-2"><ul style={{ listStyle: 'none', padding: '5px' }}>{comentariosList}</ul></div>
            <div className="mb-2" style={{display: estado == 1 || modalNoteAdmin == 1 ? 'block' : 'none' }}>  
            <label htmlFor="list-order-type" className="form-label">Resumen incidencia</label>         
                <CFormTextarea
                    rows={3}
                    onChange={handleTextChange}
                    text="Máximo 250 caracteres"
                    value={note}
                    maxLength={250}
                ></CFormTextarea></div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleConfirmation()} disabled={disabled} style={{display: estado == 1 || modalNoteAdmin == 1 ? 'block' : 'none' }}>
                    {modalNoteMode == 0 ? 'Guardar' : 'Guardar e imprimir etiqueta'}
                </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default Note;