import axios from "axios";
import HostService from "./host.service";
import AuthService from "./auth.service";

class NotesService {

    /**
     * Registrar Note (incidencias)
     * @param note
     * @returns {Promise<AxiosResponse<any>>}
     */
    static registerNotes(note) {
        console.log('registerNotes', note)
        return axios.post(HostService.getHost() + 'notes',
            {
                "serie": note.serie.toString(),
                "albaran": note.albaran.toString(),
                "id_empleado": note.id_empleado,
                "nombre_empleado": note.nombre_empleado,
                "note": note.note,
                "origen": note.origen,
                "estado": note.estado,
                "codigo_articulo": note.codigo_articulo,
                "tipo_note": note.tipo_note,
            }, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Registrar Note Comentarios (incidencias)
     * @param note
     * @returns {Promise<AxiosResponse<any>>}
     */
     static registerNotesComentarios(note) {
        console.log('registerNotesComentario', note)
        return axios.post(HostService.getHost() + 'notes/comentario',
            {
                "id_note": note.id_note,
                "comentario": note.note,
                "nombre_empleado": note.nombre_empleado
            }, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener incidencias
     * @param id_incidencia
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getNotes() {
        return axios.get(HostService.getHost() + 'notes/', AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Obtener etiquetas
     * @param id_agency
     * @returns {Promise<AxiosResponse<any>>}
     */
     static getNoteByNum(serie, albaran, origen) {
        return axios.get(HostService.getHost() + 'note/' + serie + '/' + albaran + '/' + origen, AuthService.setToken())
            .then((response) => {
                console.log('getNotesByNum', response.data)
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Borrar incidencia
     * @param id_order
     * @returns {Promise<AxiosResponse<any>>}
     */
    static deleteNote(serie, order, counter, year) {
        return axios.delete(HostService.getHost() + 'etiquetas/' + serie + '/' + order + '?contador=' + counter + '&ejercicio='+year, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Modificar incidencia
     * @returns {Promise<AxiosResponse<any>>}
     */
     static updateNote(note) {
        return axios.put(HostService.getHost() + 'notes/'+note.id_note, { 
            note: note.note, 
            estado: note.estado,  
        }, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    static getInfoInvoice(serie, albaran, ejercicio) {
        return axios.get(HostService.getHost() + 'invoice/'+serie + '/' + albaran, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

}

export default NotesService;