import React, {useContext, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CFormTextarea, CModalTitle, CFormSelect} from "@coreui/react";
import EmployeesService from "../../services/employees.service";
import CIcon from "@coreui/icons-react";
import {cilWarning} from "@coreui/icons";


const NoteCreate = ({show, noteModal, handleClose, handleConfirm}) => {

    const [error, setError] = useState('');
    const [note, setNote] = useState('');
    const [preparers, setPreparers] = useState([]);
    const [prepare, setPrepare] = useState('');
    const [prepareName, setPrepareName] = useState('');
    const [estados, setEstados] = useState([{label:'Abierto',value: '1'},{label:'En Revisión',value: '2'},{label:'Cerrado',value: '3'}]);
    const [estado, setEstado] = useState('');
    const [disabled, setDisabled] = useState(true);
    

    useEffect(() => {
        console.log('useEffect', noteModal)
        setNote('');
        

    }, [noteModal]);

    const initSelects = async (texto) => {
        let employees = await EmployeesService.getEmployees();

        let employeesMap = employees.map((employee) => {
            return {label: employee.nombre, value: employee.id}
        });

        employeesMap.unshift({label: texto, value: '0'});
        return employeesMap;
    }

    const handleTextChange = (e) => {
        const value = e.target.value;
        if (value.length > 170) {
            setError('El comentario no puede exceder los 70 caracteres.');
        } else {
            setNote(value);
            setError('');
            setDisabled(false);
        }
    };

    const handleConfirmation = () => {
        if (note.length > 170) {
            setError('El comentario excede los 70 caracteres.');
        } else {
            noteModal.note = note;
            noteModal.id_empleado = prepare;
            noteModal.nombre_empleado = prepareName;
            noteModal.estado = estado;

            handleConfirm(noteModal);
        }
    };

    const handleChange = (event, type) => {
        let index = event.target.selectedIndex;
        if (type === 'orderState') {
            setOrderStateType(event.target.value);
        } else  if (type === 'prepare') {
            setPrepare(event.target.value);
            setPrepareName(event.target.options[index].text);
        } else  if (type === 'estado') {
            setEstado(event.target.value);
        } else {
           
        }
    };
  
    return (
        
        <Modal size="lg" show={show} onHide={() => handleClose()} centered >
                <Modal.Header closeButton>
                    <CModalTitle>
                        <CIcon icon={cilWarning} size='sm' />
                        Crear nueva incidencia
                    </CModalTitle>
                </Modal.Header>
            <Modal.Body>
            <div className="mb-2">   
                <Row>
                    <Col>1 of 3</Col>
                    <Col>2 of 3</Col>
                    <Col>3 of 3</Col>
                </Row>
            </div>
            <div className="mb-1"></div>
            <div className="mb-2">
                            <label htmlFor="list-order-type" className="form-label">Tramitado por</label>
                            <CFormSelect className='form-control'
                                         onChange={(e) => handleChange(e, 'prepare')}
                                         id="preparer"
                                         value={prepare}
                                         name="preparer"
                                         aria-label="Seleccionar preparador"
                                         options={preparers}
                            />
            </div> 
            
            <div className="mb-2">
                            <label htmlFor="list-order-type" className="form-label">Estado</label>
                            <CFormSelect className='form-control'
                                disabled
                                value={estado}
                                aria-label="Seleccionar estado"
                                options={estados}
                            />
            </div> 
            <div className="mb-2">  
            <label htmlFor="list-order-type" className="form-label">Resumen incidencia</label>         
                <CFormTextarea
                    rows={3}
                    onChange={handleTextChange}
                    text="Máximo 70 caracteres"
                    value={note}
                    maxLength={70}
                ></CFormTextarea></div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleConfirmation()} >
                    Guardar
                </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default NoteCreate;